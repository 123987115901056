@media only screen and (max-width: 1599px) {

  #home-slider {
    height:400px;
  }
  #slider {
    height:400px;
    min-height:400px;
    z-index:1;

    .owl-item {
      height:400px;
      .slide {
        height:400px !important;

        .item-text {
          padding-top:90px;
          .title {
            font-size:36px;
            width:600px;
            height:192px;
            span {
              line-height:58px;
            }
          }

        }
      }
    }
  }
}

@media only screen and (max-width: 1399px) {

  #home-slider {
    height:380px;
  }
  #slider {
    height:380px;
    min-height:380px;
    z-index:1;

    .owl-item {
      height:380px !important;
      .slide {
        height:380px !important;

        .item-text {
          padding-top:70px;
          .title {
            font-size:30px;
            line-height:44px;
            width:500px;
            height:155px;
            span {
              line-height:44px;
            }
            span + span {
              font-size:50px;
            }
          }

        }
      }
    }
  }

  .navbar-default {
    top:120px;
  }

  .contactgegevens {
    height:120px;
    .table-container {
      height:120px;
      .table-inner {
        .logo {
          max-width:230px;
        }
        .contact {
          ul {
            li {
              a {
                padding:6px 40px 3px 40px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1399px) {
  .navbar-form {
    width:220px;
  }
  header {
    .title {
      .v-container {
        .v-inner {
          span + span {
            font-size:44px;
            line-height:44px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {}
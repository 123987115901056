$greyLight:               #f9f9f9;
$greyDark:                #312e2f;
$grey:                    #acacac;
$greyCard:                #ebebeb;
$brown:                   #de623d;
$brown-dark:              #BF411C;
$brown-darker:            #982807;
$green:                   #bfc33b;
$greenDark:               #939621;
$greenCard:               #939621;
$red:                     #fb3c56;

$font-standard:           'Helvetica', 'Arial', sans-serif;
$font-sans:               'Open Sans', sans-serif;
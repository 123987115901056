#home-slider {
  width:100%;
  height:400px;
  margin-top:190px;
}
#slider {
  width:100%;
  display:block;
  height:400px;
  min-height:400px;
  z-index:1;
  .owl-item {
    width:100%;
    height:400px;
    .slide {
      width:100%;
      height:400px !important;
      min-height:100%;

      .item-text {
        padding-top:90px;
        padding-left:1%;
        .title {
          display:block;
          text-align: left;
          padding:10px 0 0 15px;
          margin-top:0;
          margin-left:90px;
          font-size:36px;
          width:600px;
          height:192px;
          background:white;
          color:$brown;
          text-shadow:0 1px 0 rgba(255, 255, 255, 0.4);
          font-family: $font-standard;
          font-weight:300;
          z-index:1001 !important;
          span {
            font-weight:300;
            line-height: 58px;
            text-align: left;
            display: block;
            text-transform: uppercase;
          }
          span + span {
            font-weight: 700;
            font-size: 65px;
          }
        }
      }
    }
  }

    .owl-dots {
      height:25px;
      position:absolute;
      bottom:15px;
      left:50%;
      transform:translate(-50%,-50%);
      display: inline-block;
      zoom: 1;

      .owl-dot {
        width: 25px;
        height: 25px;
        margin: 0 5px 0 0;
        background: $brown;
        border:3px solid white;
        display: block;
        float:left;
        -webkit-backface-visibility: visible;
        -webkit-transition: opacity 200ms ease;
        -moz-transition: opacity 200ms ease;
        -ms-transition: opacity 200ms ease;
        -o-transition: opacity 200ms ease;
        transition: opacity 200ms ease;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        border-radius: 40px;
        &.active {
          background:white;
        }
        &:last-child {
          margin:0;
        }
      }
    }

}

#home-slider {
  position:relative;
  z-index:0;
}




.form-contact {
  padding-right:45px;
}
.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  font-size: 18px;
  font-weight: 300;
  color: $brown;
  height: 56px;
  padding:8px 20px;
  border-color: #ddd;
  box-shadow: none;
  font-family: $font-standard;
  &:focus,
  &:active {
    box-shadow: none;
    border-color: $brown;
  }
}
.form-small {
  float:left;
  width:30%;
  padding-left:4px;
}
.form-medium {
  float:left;
  width:70%;
  padding-right:4px;
}
.form-large {
  float:left;
  width:100%;
}

.form-group {
  margin: 0 0 8px 0;
  float: left;
  width: 100%;
}

textarea.form-control {
  resize: none;
  height: 250px;
  padding:15px 20px;
}

.alert {
  border-radius: 0;
  font-size: 1.25em;
  ul {
    margin: 0 0 0 19px;
    padding: 0;
    li {
      padding: 2px 0;
    }
  }
}

.has-error {
  position: relative;
  .form-control {
    border-color: $red;
  }
  &:after {
    content: "\f06a";
    font-family: FontAwesome;
    position: absolute;
    right: 15px;
    top: 14px;
    color: $red;
    font-size: 20px;
  }
}
.radio-inline.has-error {
  &:after {
    top:-1px;
    right:-25px;
  }
}

.alert-danger {
  background: rgba(238, 115, 11, 0.1);
  color: $red;
  border-color: $red;
  border: 0;
}

#home-form {
  .form-control::-webkit-input-placeholder {
    color: #abacad;
  }
  .form-control:focus::-webkit-input-placeholder {
    color: $brown;
    transition:color 0.25s ease;
  }
  .form-control::-moz-placeholder {
    color: #abacad;
  }
  .form-control:focus::-moz-placeholder {
    color: $brown;
    transition:color 0.25s ease;
  }
  .form-control:-ms-input-placeholder {
    color: #abacad;
  }
  .form-control:focus:-ms-input-placeholder {
    color: $brown;
    transition:color 0.25s ease;
  }
}
.form-content {
  h2 {
    margin:0 0 30px 0;
    padding:0;
    text-align:center;
  }
}

#contact-form {
  .verplicht {
    font-size:13px;
    color:#aaa;
  }
}
.label {
  padding:.3em .6em .3em;
}

label.radio-inline {
  margin-top:15px !important;
  color:#abacad;
  font-size:18px;
  font-weight:300;
  font-family: $font-standard;
  input[type="radio"] {
    margin-top:6px;
  }
}
label.radio-inline + label.radio-inline {
  margin-left:30px;
}


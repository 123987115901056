html, body {
  padding:0;
  margin:0;
  font-family: $font-standard;
  font-size:16px;
  line-height:1.9em;
  color:$greyDark;
  position:relative;
  background:$greyLight;
}
html {
  height:100%;
}
body {
  min-height:100%;
  height:100%;
  overflow-x:hidden;
  font-weight:300;
}
a {
  color:$brown;
  &:hover,
  &:focus {
    outline:0;
    color:$brown-dark;
  }
}
.table-container {
  display: table;
  .table-inner {
    display: table-cell;
    vertical-align:middle;
  }
}
.btn-primary {
  background:$brown;
  color: $brown-darker;
  text-decoration: none;
  display:block;
  border:0;
  border-bottom:3px solid $brown-dark;
  text-transform: uppercase;
  text-shadow:none;
  padding:15px 30px 13px 30px;
  font-weight:bold;
  transition: all 0.15s ease-in-out;
  &:hover,
  &:focus {
    background:$greyDark;
    border-color:black;
    color:white;
  }
}
p {
  margin:0 0 2em 0;
}
.wrapper {
  position: relative;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto 0 auto;
  background: white;
  z-index: 999;
  display:block;
  overflow:auto;
  box-shadow: 0 0 6px #ccc;
}
.banner {
  width:100%;
  float:left;
  text-transform: uppercase;
  padding:30px 60px;
  font-size:22px;
  line-height:36px;
  font-family: $font-standard;
  h1 {
    margin:0;
    padding:0;
    font-size:22px;
    line-height:36px;
    font-family: $font-standard;
  }
  &.green {
    background:$brown;
    color:$greyDark;
  }
}
.overview {
  background:white;
  padding:60px 0 30px 0;
  width:100%;
  float:left;
}
.page-title {
  background:$greyDark;
  float:left;
  width:100%;
  padding:18px 0 18px 0;
  h1 {
    font-size:44px;
    color:$brown;
    margin:0;
    padding:0;
    font-weight:700;
  }
  h2,
  a.back {
    font-size:18px;
    font-weight:300;
    margin:3px 0 0 0;
    padding:0;
    color:white;
    text-transform: uppercase;
    font-family: $font-standard;
  }
  a.back {
    margin:4px 0 0 0;
    float:left;
    i {
      margin-right:4px;
    }
    &:hover,
    &:focus {
      text-decoration: none;
      color:$grey;
    }
  }
}
.fotos,
.main-content {
  float:left;
  width:100%;
  padding:30px 0;
  h2, h3 {
    margin:0 0 5px 0;
    padding:0;
    font-weight:700;
  }
  h2 {
    color:$brown;
  }
  p, ul {
    margin:0 0 27px 0;
  }
  ul {
    padding:0 0 0 18px;
  }
  .form-block,
  .text-block {
    padding-right:30px;
    float:left;
    width:100%;
  }
}

.text {
  padding:60px 0;
  float:left;
  position:relative;
  width:100%;
  .text-block {
    padding-right:45px;
    h1 {
      color:$brown;
      margin:0 0 15px 0;
    }
    ul {
      margin:0 0 30px 0;
      padding:0;
      list-style:none;
      li {
        position:relative;
        display:block;
        border-bottom:1px solid $greyCard;
        padding:0;
        a {
          position:relative;
          padding:7px 0 5px 22px;
          &:before {
            content:"\f1c1";
            position:absolute;
            left:0;
            top:0;
            color:$brown;
            font-family: FontAwesome;
          }
        }
        &:before {

        }
        &:last-child {
          border-bottom:0;
        }
      }
    }
  }
}
.sidebar {
  .thumbnail {
    background:#fafafa;
    border-radius:0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border:1px solid #f2f2f2;
  }
  h2 {
    padding: 18px 15px 16px 15px;
    margin: 0;
    color: $brown-darker;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: $brown;
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
  }
  h3 {
    margin:0;
    padding:0;
    color:$greyDark;
    font-weight:700;
    font-size:1.25em;
    line-height:27px;
  }
  h4 {
    font-size:0.8em;
    margin:5px 0 15px 0;
    padding:0;
    color:#aaa;
  }
  p {
    font-size:15px;
    margin:0 0 8px 0;
    color:#aaa;
  }
  .btn-primary {
    i {
      margin-right:8px;
    }
  }
  .btnNews {
    position:relative;
    font-weight:normal;
    padding-right:22px;
    color:$brown;
    &:after {
      content:"\f061";
      font-family: FontAwesome;
      position:absolute;
      right:0;
      top:0;
      line-height:16px;
      color:$brown;
    }
    &:hover,
    &:focus {
      text-decoration:none;
      color:$brown-dark;
      &:after {
        color:$brown-dark;
      }
    }
  }
}

.news-overview {
  float:left;
  width:100%;
  padding:60px 0;
}
.card {
  width:100%;
  float:left;
  border-radius:5px;
  border-bottom:3px solid $greyCard;
  background:$greyLight;
  transition: all 0.2s ease;
  position:relative;
  margin:0 0 30px 0;
  .image {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: relative;
    width: 40%;
    height: 100%;
    display: block;
    float:left;
    overflow:hidden;
    z-index:9;
    img {
      transition: all 0.2s ease;
    }
    &:before {
      content: "\f002";
      font-family: FontAwesome;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 10px;
      width: 40px;
      height: 42px;
      line-height: 40px;
      text-align: center;
      opacity: 0;
      transition: all 0.2s ease;
      z-index: 101;
    }
    &:after {
      content:"";
      background: rgba(222,98,61,0.8);
      width:100%;
      height:100%;
      position:absolute;
      top:0;
      left:-100%;
      transition: all 0.2s ease;
    }
  }
  .txt {
    float:left;
    width:60%;
    display:block;
    position:relative;
    padding:15px 20px;
    color:$greyDark;
    h2 {
      margin:0 0 5px 0;
      padding:0;
      color:$brown;
      display:block;
      width:100%;
      float:left;
      font-family: $font-sans;
      float: left;
      span {
        display:block;
        text-align:left;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        text-transform: inherit;
      }
      span + span {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 16px;
      }
    }
    span.desc {
      font-size:16px;
      line-height:22px;
      float:left;
      width:100%;
    }
  }
  &:after {
    content:"\f061";
    font-family: FontAwesome;
    position:absolute;
    right:0px;
    bottom:10px;
    width:40px;
    height:30px;
    font-size:30px;
    color:$greyCard;
  }
  &:hover,
  &:focus {
    border-color:$brown-darker;
    .image {

      &:before {
        top: 50%;
        left:50%;
        font-size: 40px;
        opacity: 1;
      }

      &:after {
        top:0;
        left:0;
      }
    }
    &:after {
      color:$brown;
    }
  }
}
